<script setup>
import { ref } from 'vue';
import publicApi from '../../../../api/publicApi';
import BannerCard from './BannerCard.vue';

const { data } = await publicApi.get('/home-banner?populate[0]=Banner&populate[1]=Banner.Imagen')
const banners = ref(data.data.attributes.Banner)
</script>

<template>
    <BannerCard v-for="banner of banners" :key="banner.id" :informacion="banner"  />
</template>


<style lang="scss" scoped>

</style>