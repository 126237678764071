<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

const router = useRouter()

const props = defineProps(['informacion'])

const goTo = (url) => router.push(url)

</script>

<template>
    <div class="banner-card fade-in" :style="'background-image: url('+props.informacion.Imagen.data.attributes.url+')'" @click="goTo(props.informacion.Enlace)">
        <div class="titulo">{{props.informacion.Titulo}}</div>
    </div>
</template>



<style lang="sass" scoped>
.banner-card
    height: 300px
    background-size: cover
    position: relative
    cursor: pointer
    @include breakpoint(md)
        width: 50%
    .titulo
        font-size: 1.5rem
        font-weight: 800
        background-color: rgba(0, 33, 99, 0.5607843137)
        color: #fff
        padding: 10px 0px
        position: absolute
        width: 100%
        bottom: 0px
</style>